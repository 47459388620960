'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/button';
import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';

import { CONFIG } from '@/config';

import './index.css';
import './links.css';
import './cta.css';

export const HeaderClient = ({
  blok,
  ctaOverwrite,
  overrideHeader = false,
}) => {
  const pathname = usePathname();
  const url = `${pathname}`;
  const isHomePage = url === '/';

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    setScrolled(window.scrollY > 0);
  }, [setScrolled]);

  useEffect(() => {
    handleScroll();

    document.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const cta = useMemo(() => {
    const array = blok.content.cta.slice();

    for (const [index, item] of ctaOverwrite?.entries?.() || []) {
      array.splice(index, 1, item);
    }

    return array;
  }, [blok.content.cta, overrideHeader]);

  return (
    <header
      className={classNames('header', {
        'header--scrolled': scrolled,
        'header--other': !isHomePage,
        'header--override-header': overrideHeader,
      })}
    >
      <MaxWidth>
        <Link href="/" className="header__logo">
          <img
            src="/static/unlockm.svg"
            width="182"
            height="44"
            alt={CONFIG.title}
          />
        </Link>

        <div className="header__links">
          {blok.content.links?.map?.((item) => {
            const hasSubLinks = item.subLinks?.length > 0;

            return (
              <div
                key={item._uid}
                className={classNames('header__link', {
                  'header__link--has-sub-links': hasSubLinks,
                })}
              >
                <Link link={item.link}>
                  {item.title}

                  {hasSubLinks ? (
                    <img
                      className="header__link__sub-icon"
                      src="/static/icons/triangle-down-white.svg"
                      width="10"
                      height="10"
                      alt=""
                    />
                  ) : null}
                </Link>

                {hasSubLinks ? (
                  <div className="header__link__sub-links">
                    {item.subLinks?.map?.((subLink) => {
                      return (
                        <Link
                          key={subLink._uid}
                          link={subLink.link}
                          className={classNames('header__link__sub-link')}
                        >
                          {subLink.title}
                        </Link>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>

        <div className="header__cta">
          {cta?.map?.((item) => {
            return (
              <Button
                key={item._uid}
                link={item.link}
                theme={item.theme || 'primary'}
              >
                {item.title}
              </Button>
            );
          })}
        </div>
      </MaxWidth>
    </header>
  );
};
