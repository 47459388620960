export const CONFIG = {
  title: 'UnlockM',
  titleSuffix: ' - UnlockM',

  description:
    'We help you to build an efficient marketing engine for a sustainable and fast growth. Level Up your Marketing Engine, Accelerate your Growth.',

  storyblokRegion: 'us',
};

/**
 * @typedef {Object} Locale
 * @property {string} title
 * @property {string} shortName
 * @property {string} locale
 * @property {string} shortCode
 * @property {string} pathPrefix
 */

/**
 * @type {Object<string, Locale>}
 */
export const LOCALE = {
  FR: {
    title: 'Français',
    shortName: 'FR',
    locale: 'fr-fr',
    shortCode: 'fr',
    pathPrefix: '/',
  },
  // EN: {
  //   title: 'English',
  //   shortName: 'EN',
  //   locale: 'en-us',
  //   shortCode: 'en',
  //   pathPrefix: '/en',
  // },
};

/**
 * @type {Locale[]}
 */
export const LOCALES = [LOCALE.FR];

export const DEFAULT_LOCALE = LOCALES[0];
