'use client';

import NextLink from 'next/link';

import { pushDataLayerEvent } from '@/helpers/analytics/data-layer';
import { isInStoryblok } from '@/helpers/client/storyblok';
import { linkResolver } from '@/helpers/storyblok/link-resolver';

import { DEFAULT_LOCALE } from '@/config';

/**
 * @param {Object} props
 * @param {import('storyblok-js-client').ISbLinkURLObject|null} [props.link]
 * @param {import('@storyblok/js').ISbStoryData} [props.page]
 * @param {string} [props.className]
 * @param {boolean} [props.disabled]
 * @param {string} [props.href]
 * @param {any} [props.dangerouslySetInnerHTML]
 * @param {any} [props.children]
 * @param {any} [props.timeout]
 * @param {Object|null} [props.offsetElement=null]
 */
export const Link = ({
  className,
  children,
  link,
  page,
  disabled,
  timeout = null,
  offsetElement = null,
  ...props
}) => {
  let href = props.href || linkResolver(link, page) || '';

  const urlParams = href.split('/');

  if (urlParams[0] === '') {
    urlParams.shift();
  }

  if (
    urlParams[0] &&
    urlParams[0].toLowerCase() === DEFAULT_LOCALE.shortCode.toLowerCase()
  ) {
    urlParams.shift();
    href = `/${urlParams.join('/')}`;
  }

  delete props.href;

  if (href === '#cookie-consent-popup') {
    const openCookieConsent = async () => {
      if (typeof window !== 'undefined') {
        const VanillaCookieConsent = await import('vanilla-cookieconsent');

        VanillaCookieConsent?.showPreferences?.();
      }
    };

    return (
      <button onClick={openCookieConsent} className={className} {...props}>
        {children}
      </button>
    );
  }

  if ((href || '').match(/^https:\/\/a.storyblok.com\/.*\.pdf$/)) {
    const pdfHref = href.replace(/^https:\/\/a.storyblok.com\//, '/static/');

    return (
      <a href={pdfHref} target="_blank" rel="noopener" {...props}>
        {children}
      </a>
    );
  }

  const target =
    props.target || (href || '').match(/^(https?:)?\/\//) ? '_blank' : null;

  const rel = props.rel || target === '_blank' ? 'noopener noreferrer' : null;

  const handleClick = (event) => {
    if (isInStoryblok()) {
      event.preventDefault();
    }

    pushDataLayerEvent({
      event: 'link_click',
      area: document.querySelector('.header')?.contains?.(event.target)
        ? 'header'
        : document.querySelector('.footer')?.contains?.(event.target)
          ? 'footer'
          : 'body',
      label: event.target.innerText,
    });

    if (timeout && timeout.function && typeof timeout.function === 'function') {
      setTimeout(() => {
        timeout.function();
      }, timeout.time || 200);
    }

    if (href.startsWith('#') && typeof document !== 'undefined') {
      event.preventDefault();

      const target = document.querySelector(href);

      if (target) {
        event.preventDefault();

        const targetScrollOffsetTop =
          parseInt(
            window
              .getComputedStyle(target)
              .getPropertyValue('--js-scroll-offset-top') || '0',
            10,
          ) || 0;

        let top =
          (document.scrollingElement?.scrollTop || 0) +
          target.getBoundingClientRect().top -
          targetScrollOffsetTop;

        if (offsetElement)
          top = top - parseInt(getComputedStyle(offsetElement).height);

        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <NextLink
      onClick={handleClick}
      href={
        href
          ? href.startsWith('#')
            ? href
            : {
                pathname: href,
                query: link?.params || {},
              }
          : ''
      }
      target={target}
      rel={rel}
      className={className}
      {...props}
    >
      {children}
    </NextLink>
  );
};
