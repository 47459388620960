import NextImage from 'next/image';
import { forwardRef } from 'react';

import {
  DEFAULT_FOCUS_POINT,
  FOCUS_POINT,
  generateImageProps,
  getImageDimensions,
  isImage,
} from '@/helpers/image';

/**
 * @typedef {Object} ImageComponentProps
 * @property {Object} props.image
 * @property {string} props.sizes
 * @property {boolean} [props.lazy=false]
 * @property {string} [props.draggable='true']
 * @property {string} [props.focusPoint=null]
 *
 * @property {string} [props.className=undefined]
 * @property {any} [props.onClick=undefined]
 * @property {string} [props.fetchPriority=undefined]
 */

/**
 * @param {ImageComponentProps} props
 */
export const Image = forwardRef(
  /**
   * @param {ImageComponentProps} props
   */
  function ImageWithRef(
    {
      image,
      sizes,
      lazy = false,
      draggable = 'true',
      focusPoint = null,
      className,
      ...rest
    },
    ref,
  ) {
    if (isImage(image)) {
      const objectPosition = FOCUS_POINT[focusPoint] || DEFAULT_FOCUS_POINT;

      const props = {
        ref,
        draggable: draggable === 'true' ? undefined : draggable,
        className,
        style:
          objectPosition === 'center'
            ? null
            : {
                objectPosition,
              },
      };

      const src = image?.filename;

      if (/\.svg$/.test(src)) {
        return (
          <img
            {...generateImageProps(image, {
              sizes,
              lazy,
            })}
            {...props}
            {...rest}
          />
        );
      }

      const dimensions = getImageDimensions(src);

      return (
        <NextImage
          src={src}
          width={dimensions.width}
          height={dimensions.height}
          alt={image.alt}
          sizes={sizes}
          loading={lazy ? 'lazy' : undefined}
          {...props}
          {...rest}
        />
      );
    }

    return null;
  },
);
