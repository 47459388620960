import classNames from 'classnames';

import './index.css';

export const MaxWidth = ({ children, className = null, ...rest }) => {
  return (
    <div className={classNames('max-width', className)} {...rest}>
      {children}
    </div>
  );
};
