export const FOCUS_POINT = {
  middle: 'center',
  'top-left': 'top left',
  top: 'top',
  'top-right': 'top right',
  right: 'right',
  'bottom-right': 'bottom right',
  bottom: 'bottom',
  'bottom-left': 'bottom left',
  left: 'left',
};

export const DEFAULT_FOCUS_POINT = FOCUS_POINT.middle;

const WIDTHS = [
  48, 96, 192, 240, 320, 375, 425, 625, 769, 825, 1025, 1280, 1440, 1920, 2560,
  3072,
];

export const isImage = (image) => {
  return typeof image === 'object' && image?.filename;
};

/**
 * @param {string} url
 * @param {number|null} [width=null]
 */
export const changeImageUrl = (url, width = null) => {
  return `${url}/m/${width ? `${width}x0` : ''}`;
};

export const getImageDimensions = (url = '') => {
  const dimensions = {};

  const width = url.split('/')[5].split('x')[0];
  const height = url.split('/')[5].split('x')[1];

  if (typeof width === 'string' && typeof height === 'string') {
    dimensions.width = parseInt(width, 10);
    dimensions.height = parseInt(height, 10);
  }

  return dimensions;
};

export const augmentDimensions = (dimensions) => {
  if (dimensions.width && dimensions.height && typeof window !== 'undefined') {
    const factor = Math.min(
      Math.max(
        1,
        Math.max(window.screen.width, window.innerWidth) / dimensions.width,
      ),
      Math.max(
        1,
        Math.max(window.screen.height, window.innerHeight) / dimensions.height,
      ),
    );

    return {
      width: dimensions.width * factor,
      height: dimensions.height * factor,
    };
  }

  return dimensions;
};

export const generateSrcSet = (url, dimensions) => {
  const list = [];

  for (const width of WIDTHS) {
    // Due to the migration process of this project, sometimes the width in the
    // image URL is smaller than its original width.
    // if (dimensions.width && dimensions.width <= width) {
    //   list.push(
    //     `${changeImageUrl(url, dimensions.width)} ${dimensions.width}w`
    //   );

    //   break;
    // }

    list.push(`${changeImageUrl(url, width)} ${width}w`);
  }

  return `${list.join(',')}`;
};

/**
 * @typedef {Object} ImageProps
 * @property {string} alt
 * @property {string} [loading]
 * @property {string} [src]
 * @property {string} [srcSet]
 * @property {string} [sizes]
 * @property {number} [width]
 * @property {number} [height]
 * @property {string} [draggable]
 */

/**
 * @param {Object} image
 * @param {Object} params
 * @param {string} params.sizes
 * @param {boolean} [params.lazy=false]
 * @param {string} [params.draggable='true']
 * @returns {ImageProps}
 */
export const generateImageProps = (image, { lazy = false, sizes }) => {
  const url = image?.filename;

  if (!url) {
    return {
      alt: '',
    };
  }

  const dimensions = getImageDimensions(url);

  const isSvg = /\.svg$/.test(url);

  const src = isSvg ? url : changeImageUrl(url);
  const srcSet = isSvg ? null : generateSrcSet(url, dimensions);

  return {
    ...(lazy
      ? {
          loading: 'lazy',
        }
      : {}),
    src,
    srcSet,
    sizes,
    alt: image.alt ?? '',
    width: dimensions?.width ?? undefined,
    height: dimensions?.height ?? undefined,
  };
};

export const ASSET_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const getAssetType = (url) => {
  const extensionsImages = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
  const extensionsVideos = [
    '.mp4',
    '.avi',
    '.mov',
    '.mkv',
    '.webm',
    '.flv',
    '.wmv',
  ];

  if (extensionsImages.some((ext) => url.toLowerCase().endsWith(ext)))
    return ASSET_TYPE.IMAGE;
  if (extensionsVideos.some((ext) => url.toLowerCase().endsWith(ext)))
    return ASSET_TYPE.VIDEO;
  return null;
};
