'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';
import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';

export const HeroClient = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h1';

  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames(
        'hero',
        blok.gradient ? `hero--gradient-${blok.gradient}` : null,
        blok.size ? `hero--size-${blok.size}` : null,
      )}
    >
      <div className="hero__gradient" />

      {isImage(blok.backgroundLeft) || isImage(blok.backgroundRight) ? (
        <div className="hero__background">
          <Image
            className="hero__background--left"
            image={blok.backgroundLeft}
            sizes="40vw"
          />
          <Image
            className="hero__background--right"
            image={blok.backgroundRight}
            sizes="40vw"
          />
        </div>
      ) : null}

      <MaxWidth className="hero__content">
        <TitleEl
          className="hero__title"
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(blok.title),
          }}
        />

        {hasText(blok.description) ? (
          <div className="hero__description">{render(blok.description)}</div>
        ) : null}

        {blok.cta?.length > 0 ? (
          <div className="hero__cta">
            {(blok.cta || []).map((link) => {
              return (
                <Button
                  key={link._uid}
                  {...storyblokEditable(link)}
                  link={link.link}
                  theme={link.theme || 'transparent'}
                >
                  {link.title}
                </Button>
              );
            })}
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
